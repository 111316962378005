import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "@components/Hero/Hero";
import * as styles from "./_OrdersHero.module.scss";

export const OrdersHero = () => {
	const props = {
		title: "Simplify order management in LIMS for easier tracking",
		description:
			"Streamline your order processes, from the moment a request is made to the item’s receipt, all within a centralized biopharma LIMS environment.",
		image: (
			<StaticImage
				loading="eager"
				src="../../_assets/orders_product.png"
				alt="Genemod Orders Hero Image"
				className={styles.image}
			/>
		),
		className: styles.heroWrapper,
		textButtonsContainerOverride: styles.textButtonsContainerOverride,
		wrapperOverride: styles.wrapperOverride,
		headerTextOverride: styles.headerTextOverride,
		bodyTextOverride: styles.bodyTextOverride,
	};

	return (
		<div>
			<Hero {...props} generalSansFont tabletTextAlignment="center" />
		</div>
	);
};
